"use client"

import { getCustomerRefferal, getUserCredentialApi } from "@/services/Redux/Reducer/CustomerSlice";
import localStorageCall from "@/services/Methods/localstorage.hook";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { _logoutFunction, storeAssigned } from "@/services/Methods/normalMethods";
import _ from "lodash";
const STORES = ['us', 'au', 'br', 'bs', 'ca', 'co', 'hk', 'id', 'jm', 'my', 'nz', 'pa', 'pe', 'ph', 'sg', 'eu', 'at', 'be', 'bg',
    'bw', 'ch', 'cy', 'cz', 'de', 'dk', 'ee', 'es', 'fi', 'fr', 'gb', 'gh', 'gr', 'hr', 'hu', 'ie', 'in', 'is', 'it', 'jp', 'mx',
    'ke', 'lt', 'mt', 'mz', 'na', 'nc', 'ng', 'nl', 'no', 'pl', 'pt', 're', 'ro', 'rs', 'ru', 'se', 'si', 'sk', 'tz', 'ug', 'za', 'zm', 'zw', 'tw', 'pf'];

const useRefferalHook = (props, section) => {

    const { pathname, router, isUserLogin, params, searchParams } = props;

    const dispatch = useDispatch();
    const [pageNotFound, setPageNotFound] = useState(false);
    const [urlRefferal, setUrlRefferal] = useState(null);
    const [isReferralLoading, setLoading] = useState(true);
    const [refferalCheckPopup, setRefferalCheckPopup] = useState(false);

    useEffect(() => {
        const redirectTo = searchParams.get("redirectTo");
        if (redirectTo && isUserLogin) {
            _logoutFunction("notRedirect");
            router.push(`/${storeAssigned()}/main-enrollment`);
        }
    }, [])


    useEffect(() => {
        // if (section === 'urlRefferalCheck') {
        //     console.log("params?.store",params?.store)
        //     _checkRefferalCode(params?.store, section);

        //     // && _.includes(STORES, params?.store)
        // }
        if (pathname && !searchParams.get('ssokey') && _.includes(STORES, params?.store)) {
            setTimeout(() => setLoading(false), 2000);
        }
    }, [pathname]);
    useEffect(() => {
        if (section === 'urlRefferalCheck') {
            // console.log("params?.store", params?.store)
            _checkRefferalCode(params?.store, section);

            // && _.includes(STORES, params?.store)
        }
    }, []);

    // console.log(localStorageCall().getItem("refferal_link"), 'dddddddddddddddddddddddddddddddd');

    function _handleYesClick(action = "no", refferalData) {
        if (action === 'yes') {
            localStorageCall().setItem("refferal_link", JSON.stringify(refferalData));
        }
        if (searchParams.get('partyPlanId')) {
            localStorageCall().setItem('partyPlanId', searchParams.get('partyPlanId'))
        }
        if (searchParams?.get('pid') && searchParams?.get('placement') && searchParams?.get('tree')) {
            if (isUserLogin) {
                _logoutFunction('notRedirect');
            }
            const TreeMoveData = {
                pid: searchParams.get('pid'),
                placementId: searchParams.get('placement'),
                treeType: searchParams.get('tree'),
                placementType: searchParams.get('placementType')
            }
            localStorageCall().setItem('TreeMoveData', JSON.stringify(TreeMoveData));
            // const URL = (searchParams.get('role') === 'customer') ? 'distributor' : 'distributor'
            const URL = (searchParams.get('role') === 'customer') ? 'main-enrollment' : 'main-enrollment'
            window?.location.assign(`/${storeAssigned()}/${URL}`);
        } else {
            const REDIRECT_PAGE = searchParams.get('redirectTo') ? searchParams.get('redirectTo') : "";
            if (REDIRECT_PAGE) {
                router.push(REDIRECT_PAGE ? `/${storeAssigned()}${REDIRECT_PAGE}` : `/${storeAssigned()}`);
            } else {
                router.push(`/${storeAssigned()}`)
            }
        }
    }

    function _checkRefferalCode(refferal, section = "refferalPopup", errorCallback) {
        if (!(STORES?.includes(params?.store))) {
            if (searchParams.get('ssokey')) {
                if (searchParams.get('partyPlanId')) {
                    localStorageCall().setItem('partyPlanId', searchParams.get('partyPlanId'))
                }
                const REDIRECT_PAGE = searchParams.get('redirectTo') ? searchParams.get('redirectTo') : "";
                dispatch(getUserCredentialApi(searchParams.get('ssokey'), router, REDIRECT_PAGE));
            } else {
                dispatch(getCustomerRefferal((data) => {
                    // console.log("data    >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", data)
                    if (data?.length === 0) {
                        // setPageNotFound(true);
                        router.push(`/${storeAssigned()}`)
                    } else {
                        try {
                            const refferalData = { "id": data[0]?.customerId, "name": data[0]?.fullName || data[0]?.publicProfile?.fullName, "webalies": data[0]?.webAlias, userDetail: data?.[0] };
                            localStorageCall().setItem("refferal_link", JSON.stringify(refferalData));
                            // setUrlRefferal(refferalData);
                            // setRefferalCheckPopup(true);
                            if (searchParams?.get('pid') && searchParams?.get('placement') && searchParams?.get('tree')) {
                                if (isUserLogin) {
                                    _logoutFunction('notRedirect');
                                }
                                const TreeMoveData = {
                                    pid: searchParams.get('pid'),
                                    placementId: searchParams.get('placement'),
                                    treeType: searchParams.get('tree'),
                                    placementType: searchParams.get('placementType')
                                }
                                localStorageCall().setItem('TreeMoveData', JSON.stringify(TreeMoveData));
                                const URL = (searchParams.get('role') === 'customer') ? 'main-enrollment' : 'main-enrollment'
                                window?.location.assign(`/${storeAssigned()}/${URL}`);
                            } else {
                                if (isUserLogin) {
                                    _logoutFunction('notRedirect');
                                }
                                const REDIRECT_PAGE = searchParams.get('redirectTo') ? searchParams.get('redirectTo') : "";
                                if (REDIRECT_PAGE) {
                                    window.location.assign(REDIRECT_PAGE ? `/${storeAssigned()}${REDIRECT_PAGE}` : `/${storeAssigned()}`);
                                } else {

                                    // console.log("inside-the-last-condtion************************************");

                                    window.location.assign(`/${storeAssigned()}/main-enrollment`)
                                }
                            }

                        } catch (err) {
                            console.log(err)
                        }
                    }
                }, refferal, 'urlRef'));
            }
        }
    }

    return ({
        pageNotFound, urlRefferal, setUrlRefferal, refferalCheckPopup, setRefferalCheckPopup,
        _checkRefferalCode,
        _handleYesClick,
        isReferralLoading
    })
};

export default useRefferalHook;